<template>
	<section id="dev">
		<div>
		<h1>Developer</h1>
		<p>I'm a professional fullstack developer since 2014</p>
		<p>PHP, Javascript, Python mostly, other languages on need to nerd basis</p>
		<p>I do programming tutorials and educational streams on twitch, Q&amp;A about coding and IT careers</p>
		<p>Here's a multi-lingual selenium-based general purpose housing ad scraper with built-in flask webui and geocoding that I built for fun to learn python better on livestream</p>
		<a class="twitch link" href="https://twitch.com/patricpuola"><i class="fab fa-twitch"></i> Twitch</a>
		<a class="github link" href="https://github.com/patricpuola"><i class="fab fa-github"></i> Github</a>
		</div>
		<div class="video_div">
			<img src="../assets/housesitter.gif">
		</div>
	</section>
</template>

<script>
export default {
	name: "Dev",
	props: {}
}
</script>
<style scoped>
	.video_div{
		display: grid;
		place-items: center;
	}
</style>