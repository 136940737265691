<template>
	<section id="maker">
		<div>
			<h1>Maker</h1>
			<blockquote>If a cluttered desk is a sign of a cluttered mind, of what, then, is an empty desk a sign?</blockquote>
			<span class="quote_author">Unknown</span>
			<p>I like to design, build and create things. Whether it's coding, 3d printing or playing an intrument, creation of something new is a deeply satisfying and fascinating process to me</p>
			<p>I do design things to 3D print in Blender, occasionally on livestream. You can find some of my designs on thingiverse</p>
			<p>Here's a RasPi-based automated houseplant irrigation system with soil moisture and light sensors in a 3D printed enclosure</p>
			<a class="thingiverse link" href="https://www.thingiverse.com/patricpuola/designs"><i class="fas fa-cubes"></i> Thingiverse</a>
			<a class="youtube link" href="https://www.youtube.com/channel/UCUTGp-R_mKcFHg7fhC-q26g"><i class="fab fa-youtube"></i> Youtube</a>
		</div>
		<div class="video_div">
			<video src="../assets/maker.webm" autoplay loop muted />
		</div>
	</section>
</template>

<script>
export default {
	name: "Maker",
	props: {}
}
</script>

<style scoped>
	.video_div{
		display: grid;
		place-items: center;
	}
</style>