<template>
	<div id="nav">
		<a id="nav_name" href="#">Patric Puola</a>
		<a id="nav_dev" href="#dev">Dev</a>
		<a id="nav_gamer" href="#gamer">Gamer</a>
		<a id="nav_maker" href="#maker">Maker</a>
	</div>
</template>

<script>
export default {
	name: 'Navigation',
	props: {}
}

</script>

<style scoped>
	#nav{
		font-size: 1.25em;
		padding: 1em 1em;
		position: fixed;
		color: var(--dark-shades);
		display: flex;
		grid-gap: 0.5em 2em;
		justify-items: center;
		width: 100%;
		z-index: 1;
		background-color: var(--light-shades);
	}

	@media screen and (max-width:30em) {
		#nav{
			padding: 1em 0em;
			display:grid;
			grid-template-rows: 1fr 1fr;
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-areas: "name name name" "dev gamer maker";
			bottom:0;
		}
	}

	a{
		line-height: 1em;
		color:inherit;
		text-decoration: none;
		transition: ease-in-out 0.2s all;
		user-select: none;
	}

	#nav_name{
		font-family: righteous;
		color: var(--dark-accent);
		grid-area: name;
	}

	@media(hover: hover) and (pointer: fine){
		a:hover{
			text-decoration: underline;
			text-underline-offset: 0.5em;
			color: var(--light-accent) !important;
		}
	}

	#nav_dev{
		grid-area: dev;
	}

	#nav_gamer{
		grid-area: gamer;
	}

	#nav_maker{
		grid-area: maker;
	}
</style>