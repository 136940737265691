<template>
	<section>
		<div id="main_info">
			<div id="greeting">Hi, I am</div>
			<div id="name">Patric Puola</div>
			<div id="title">IT Architect / Maker / Gamer / Streamer</div>
			<div id="social_media">
				<a class="twitch link" href="https://twitch.com/patricpuola"><i class="fab fa-twitch"></i></a>
				<a class="youtube link" href="https://www.youtube.com/channel/UCUTGp-R_mKcFHg7fhC-q26g"><i class="fab fa-youtube"></i></a>
				<a class="twitter link" href="https://twitter.com/patricpuola"><i class="fab fa-twitter"></i></a>
				<a class="discord link" href="https://discord.gg/gRb4btECEu"><i class="fab fa-discord"></i></a>
				<a class="linkedin link" href="https://www.linkedin.com/in/patricpuola/"><i class="fab fa-linkedin"></i></a>
			</div>
		</div>
		<div>
			<img id="portrait" src="../assets/suit.jpg">
		</div>
	</section>
</template>

<script>
import '../fontawesome/css/fontawesome.min.css'
import '../fontawesome/css/brands.min.css'
import '../fontawesome/css/solid.min.css'
export default {
	name: 'Home',
	props: {}
}
</script>

<style scoped>
	#main_info{
		margin: 2em 0em;
		display: grid;
		grid-template-columns: auto;
		grid-template-rows: auto max-content max-content auto;
		grid-template-areas: "greeting" "name" "title" "social_media";
		justify-items: center;
	}

	#greeting{
		align-self: self-end;
		grid-area: greeting;
	}

	#name{
		grid-area: name;
		color: var(--dark-accent);
		font-size: 2em;
		font-family: righteous;
		line-height: 1em;
		margin:0.25em 0;
	}

	#title{
		grid-area: title;
	}

	#portrait{
		grid-area: portrait;
		place-self: center;
		max-height: 50vw;
		max-width: 20em;
	}

	#social_media{
		grid-area: social_media;
		align-self: top;
		margin-top: 2em;
	}
</style>