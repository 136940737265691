<template>
  <Navigation />
  <div id="content">
    <Home />
    <Dev />
    <Gamer />
    <Maker />
  </div>
</template>

<script>
import Navigation from './components/Navigation.vue'
import Home from './components/Home.vue'
import Dev from './components/Dev.vue'
import Gamer from './components/Gamer.vue'
import Maker from './components/Maker.vue'

export default {
  name: 'App',
  components: {
    Navigation, Home, Dev, Gamer, Maker
  }
}
</script>

<style>
@font-face{
  font-family: righteous;
  src: local('righteous'), url('./fonts/righteous-font/Righteous-j7av.ttf');
}

@font-face{
  font-family: questial;
  src: local('questial'), url('./fonts/questrial-font/Questrial-eOvl.ttf');
}

:root{
  --light-shades: #F8F8FA;
  --light-accent: #B9774D;
  --main-brand: #9795A8;
  --dark-accent: #8C3A48;
  --dark-shades: #2E2632;

  --youtube-red: #ff0000;
  --twitch-purple: #9147ff;
  --twitter-blue: #1da1f2;
  --linkedin-blue: #0a66c2;
  --github-white: var(--light-shades);
  --discord-blue: #8a9cfd;
  --thingiverse-blue: #248bfb;
}

html, body{
  background-color: var(--light-shades);
  margin:0;
  scroll-behavior: smooth;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--dark-shades);
  font-family: questial;
  display: grid;
  scroll-snap-type: y mandatory;
}

@media screen and (min-width:30em){
  #app{
    font-size: x-large;
  }
}

#content > *{
  min-height: 100vh;
}

section{
  display: grid;
	perspective: 100em;
  place-content: center;
  scroll-snap-align: center;
  box-sizing: border-box;
  grid-template-columns: 1fr 1fr;
}

section > div{
  justify-self: center;
  margin: 0em 1em;
}

@media  screen and (max-width:30em) {
  section{
    grid-template-columns: 1fr;
    place-content: initial;
    justify-content: center;
    align-content: flex-start;
  }

  img, video{
    text-align: center;
  }
}

section:nth-child(even){
  background: var(--main-brand);
  color: var(--light-shades) !important;
}

section:nth-child(odd){
  background: var(--light-shades);
}

.link{
  color: var(--dark-shades);
  transition: 0.1s linear all;
  margin: 0 1em 1em 0;
  text-decoration: none;
}

.link:hover{
  transform: scale(1.25);
}

.twitch:hover{ color: var(--twitch-purple); }
.youtube:hover{ color: var(--youtube-red); }
.twitter:hover{ color: var(--twitter-blue); }
.linkedin:hover{ color: var(--linkedin-blue); }
.github:hover{ color: var(--github-white); }
.discord:hover{ color: var(--discord-blue); }
.thingiverse:hover{ color: var(--thingiverse-blue); }

img, video{
  filter: drop-shadow(0 0.25em 0.25em rgb(0 0 0 / 50%));
  border: 0.3em solid var(--dark-accent);
  transition: all linear 0.2s;
  margin: 1em 0;
  max-width: 85%;
}

@media screen and (min-width: 30em) {
  img, video{
    transform: rotateY(-20deg);
  }

  img:hover, video:hover{
    transform: rotateY(-10deg);
  }
}

blockquote{
  font-style: italic;
  margin-bottom: 0.25em;
  margin-left: 0em;
}

blockquote::before{
  content: "\"";
}

blockquote::after{
  content: "\"";
}

.quote_author::before{
  content: "- ";
}

.quote_author{
  margin-left: 0.5em;
}
</style>
