<template>
	<section id="gamer">
		<div>
			<h1>Gamer</h1>
			<p>I do variety streams on twitch, less latest trends and more of what I feel like playing at the moment</p>
			<p>Heroes of the Storm is my usual goto game but I stream others as well</p>
			<a class="twitch link" href="https://twitch.com/patricpuola"><i class="fab fa-twitch"></i> Twitch</a>
			<a class="discord link" href="https://discord.gg/gRb4btECEu"><i class="fab fa-discord"></i> House of Patric</a>
		</div>
		<div class="video_div">
			<video src="../assets/hots.webm" autoplay loop muted />
		</div>
	</section>
</template>

<script>
export default {
	name: "Gamer",
	props: {}
}
</script>

<style scoped>
	.video_div{
		display: grid;
		place-items: center;
	}
</style>